<template>
  <div class="Sustainability_Container">
    <Panorama
      v-if="loaded"
      :data="data"
      :imageURL="GetImageURL(this.data.image)"
      :subtitles="false"
      :module="'sustainability'"
      @PinClicked="PinSelected($event)"
    />
    <div class="SideMenuContainer">
      <PanelButton
        class="MapButton"
        @Clicked="$store.dispatch('sidePanel/SetMapOpen', true)"
      />
      <SidePanel />
    </div>
    <InfiniteLoader
      :color="'0,0,0'"
      v-show="!loaded"
      class="Sustainability__Loader"
    />
  </div>
</template>

<script>
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
import PanelButton from '@/components/panelButton/PanelButton.vue'
import SidePanel from '@/components/sidePanel/SidePanel.vue'
import { GetJSON } from '@/utils/filesManager'
import Panorama from '@/components/panorama/Panorama.vue'
import { EventBus } from '@/event-bus.js'

export default {
  data () {
    return {
      loaded: false,
      data: ''
    }
  },
  components: {
    InfiniteLoader,
    Panorama,
    PanelButton,
    SidePanel
  },
  async mounted () {
    await this.loadData()
    EventBus.$emit('notify', {
      type: 'warning',
      message: 'This section is not available yet',
      hidden: true
    })
  },
  computed: {
    wording () {
      return this.$store.getters['langs/wording']
    }
  },
  methods: {
    async loadData () {
      try {
        this.data = await GetJSON(
          this.$store.getters['events/getEvents'].default.interfaceContent
            .conference.data.media['en-US'][0].objects.original
        )
      } catch (e) {
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
    GetImageURL (name) {
      const wording = this.$store.getters['langs/wording']
      return wording.conference[name].media['en-US'][0].objects.original
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../styles/main.scss';

.Sustainability_Container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Sustainability__Loader {
  margin: auto;
  width: 50px;
  height: 50px;
  align-self: center;
}

.Sentence_Container {
  position: absolute;
  left: 60px;
  bottom: 3%;
  width: calc(100% - 60px);
  display: flex;
  pointer-events: none;
  @media (orientation: portrait) {
    width: 100%;
    bottom: 12%;
    left: 0;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    bottom: 3%;
  }
  @media (orientation: landscape) and (max-device-width: 768px) {
    bottom: 5%;
  }
}

.Sentence_Text {
  text-align: center;
  color: white;
  width: 60%;
  margin: 0 auto;
  font-family: 'BaronDidotT';
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
  z-index: 0;
  line-height: 130%;
  @include fluid-type($min_width, $max_width, $min_font * 0.7, $max_font * 2);
  @media (orientation: landscape) and (max-device-width: 768px) {
    margin: 0 auto;
    width: 60%;
  }
  @media (orientation: landscape) and (max-device-width: 1440px) {
    margin: 0 auto;
    width: 80%;
  }
  @media (orientation: portrait) {
    width: 80%;
    margin: 0 auto;
    @include fluid-type($min_width, $max_width, $min_font * 1.5, $max_font * 2);
  }
}

.Signature_Container {
  position: absolute;
  width: 100%;
  @media (orientation: landscape) and (max-device-width: 1440px) {
    bottom: 2em;
  }
  @media (orientation: landscape) and (min-device-width: 1440px) {
    bottom: 2em;
  }
  @media (orientation: portrait) {
    bottom: 0em;
  }
  display: flex;
}

.Signature_ContainerSmall {
  margin: auto;
  @media (orientation: landscape) and (max-device-width: 1440px) {
    width: 90%;
  }
  @media (orientation: landscape) and (min-device-width: 1440px) {
    width: 60%;
  }
  @media (orientation: portrait) {
    width: 80%;
  }
  display: flex;
  position: relative;
}

.Signature {
  position: absolute;
  right: 0;
  width: auto;
  height: 2em;
}

.SideMenuContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  pointer-events: none;
  z-index: 10;
}

.MapButton {
  pointer-events: auto;
}
</style>
